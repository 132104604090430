exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-anfrage-gesendet-tsx": () => import("./../../../src/pages/anfrage-gesendet.tsx" /* webpackChunkName: "component---src-pages-anfrage-gesendet-tsx" */),
  "component---src-pages-avgs-tsx": () => import("./../../../src/pages/avgs.tsx" /* webpackChunkName: "component---src-pages-avgs-tsx" */),
  "component---src-pages-blog-wp-post-uri-tsx": () => import("./../../../src/pages/blog/{wpPost.uri}.tsx" /* webpackChunkName: "component---src-pages-blog-wp-post-uri-tsx" */),
  "component---src-pages-coaching-anfragen-tsx": () => import("./../../../src/pages/coaching/anfragen.tsx" /* webpackChunkName: "component---src-pages-coaching-anfragen-tsx" */),
  "component---src-pages-coaching-index-tsx": () => import("./../../../src/pages/coaching/index.tsx" /* webpackChunkName: "component---src-pages-coaching-index-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-einladung-tsx": () => import("./../../../src/pages/einladung.tsx" /* webpackChunkName: "component---src-pages-einladung-tsx" */),
  "component---src-pages-gutschein-tsx": () => import("./../../../src/pages/gutschein.tsx" /* webpackChunkName: "component---src-pages-gutschein-tsx" */),
  "component---src-pages-haftungsausschluss-tsx": () => import("./../../../src/pages/haftungsausschluss.tsx" /* webpackChunkName: "component---src-pages-haftungsausschluss-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-newsletter-doi-tsx": () => import("./../../../src/pages/newsletter/doi.tsx" /* webpackChunkName: "component---src-pages-newsletter-doi-tsx" */),
  "component---src-pages-profil-tsx": () => import("./../../../src/pages/profil.tsx" /* webpackChunkName: "component---src-pages-profil-tsx" */),
  "component---src-pages-speaker-anfragen-tsx": () => import("./../../../src/pages/speaker/anfragen.tsx" /* webpackChunkName: "component---src-pages-speaker-anfragen-tsx" */),
  "component---src-pages-speaker-index-tsx": () => import("./../../../src/pages/speaker/index.tsx" /* webpackChunkName: "component---src-pages-speaker-index-tsx" */),
  "component---src-pages-studenten-tsx": () => import("./../../../src/pages/studenten.tsx" /* webpackChunkName: "component---src-pages-studenten-tsx" */),
  "component---src-pages-workshops-index-tsx": () => import("./../../../src/pages/workshops/index.tsx" /* webpackChunkName: "component---src-pages-workshops-index-tsx" */),
  "component---src-pages-workshops-speed-reading-tsx": () => import("./../../../src/pages/workshops/speed-reading.tsx" /* webpackChunkName: "component---src-pages-workshops-speed-reading-tsx" */),
  "component---src-templates-article-list-tsx": () => import("./../../../src/templates/articleList.tsx" /* webpackChunkName: "component---src-templates-article-list-tsx" */)
}

